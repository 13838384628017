@import '../../../../scss/theme-bootstrap';

.sticky-footer-block-formatter {
  background: $color-off-black;
  overflow: hidden;
  padding: 10px 0;
  font-size: 17px;
  position: relative;
  z-index: 3;
  @if $typography_cr24 == true {
    @include body-xs-uppercase;
  } @else {
    font-size: 17px;
  }
  @include breakpoint($landscape-up) {
    padding-#{$rdirection}: 10px;
  }
  &__links {
    display: flex;
    padding-#{$rdirection}: 8px;
    @include breakpoint($landscape-up) {
      display: block;
      padding-#{$rdirection}: 0;
    }
  }
  &__tout-node {
    .sticky-footer-block-tout {
      display: none;
    }
  }
  .disable-pc {
    display: block;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  .disable-mobile {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
    }
  }
  .bar {
    border-#{$rdirection}: solid 2px $white;
  }
  li {
    text-align: center;
    float: $ldirection;
    border-#{$ldirection}: 2px solid $white;
    padding: 0 5px;
    @include breakpoint($landscape-up) {
      padding: 0 20px;
      @if $typography_cr24 == true {
        @include body-xs-bold;
        text-transform: uppercase;
      } @else {
        font-weight: normal;
        letter-spacing: 0.2px;
        font-size: 17px;
      }
    }
    &.sticky-footer-block-links__right,
    &.sticky-footer-chat-block__right {
      @include breakpoint($landscape-up) {
        float: $rdirection;
        border-#{$rdirection}: solid 2px $white;
        border-#{$ldirection}: none;
      }
    }
    &.no-bar {
      border: none;
    }
  }
  .section-avhair-quiz & {
    display: none;
    @include breakpoint($portrait-up) {
      display: block;
    }
  }
}

.footer--sticky.is-sticky {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 98;
  #{$ldirection}: 0;
}

.site-footer--sticky.is-sticky {
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 101;
}
